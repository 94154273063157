export default [
    {
        path: '/login',
        name: 'auth-login',
        component: () => import('@/views/pages/authentication/Login.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
      },
      {
        path: '/auth/:id',
        name: 'auth',
        component: () => import('@/views/pages/authentication/authentification.vue'),
        meta: {
            layout: 'full',
            resource: 'Auth',
            redirectIfLoggedIn: true,
        },
      },
    {
      path: '/dashboard/analytics',
      name: 'dashboard-analytics',
      component: () => import('@/views/dashboard/analytics/Analytics.vue'),
    },
    {
      path: '/dashboard/ecommerce',
      name: 'dashboard-ecommerce',
      component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
    },
    {
        path: '/pages/pemantauan-sembako',
        name: 'pemantauan-sembako',
        component: () => import('@/views/pages/pemantauan-sembako/GoodTable.vue'),
        meta: {
          pageTitle: 'Pemantauan Sembako',
        },
    },
    {
        path: '/pages/verif-pemantauan-sembako',
        name: 'verif-pemantauan-sembako',
        component: () => import('@/views/pages/verif-pemantauan/GoodTable.vue'),
        meta: {
          pageTitle: 'Verifikasi Pemantauan Sembako',
        },
    },
    {
        path: '/pages/lihat-pemantauan-sembako/:id',
        name: 'lihat-pemantauan-sembako',
        component: () => import('@/views/pages/verif-pemantauan/lihat_pemantauan/Table.vue'),
    },
    {
        path: '/pages/tambah-pemantauan-sembako',
        name: 'tambah-pemantauan-sembako',
        component: () => import('@/views/pages/pemantauan-sembako/tambah_pemantauan/Table.vue'),
        meta: {
          pageTitle: 'Tambah Pemantauan Sembako',
        },
    },
    {
        path: '/pages/edit-pemantauan-sembako/:id',
        name: 'edit-pemantauan-sembako',
        component: () => import('@/views/pages/pemantauan-sembako/edit_pemantauan/Table.vue'),
    },
    {
        path: '/grafik/pemantauan/ketersediaan',
        name: 'grafik-ketersediaan',
        component: () => import('@/views/pages/grafik/ketersediaan/ketersediaanChart.vue'),
        meta: {
          pageTitle: 'Pemantauan Ketersediaan Sembako',
          // breadcrumb: [
          //   {
          //     text: 'Extensions',
          //   },
          //   {
          //     text: 'Apex Chart',
          //     active: true,
          //   },
          // ],
        },
    },
    {
      path: '/grafik/pemantauan/harga',
      name: 'grafik-harga',
      component: () => import('@/views/pages/grafik/harga/hargaChart.vue'),
      meta: {
        pageTitle: 'Pemantauan Harga Sembako',
        // breadcrumb: [
        //   {
        //     text: 'Extensions',
        //   },
        //   {
        //     text: 'Apex Chart',
        //     active: true,
        //   },
        // ],
      },
    },
    {
      path: '/grafik/pemantauan/ketersediaandanharga',
      name: 'grafik-ketersediaandanharga',
      component: () => import('@/views/pages/grafik/ketersediaandanharga/Leaflet.vue'),
      meta: {
        pageTitle: 'Pemantauan Sembako',
      },
  },
  {
    path: '/grafik/pemantauan/harga/:id',
    name: 'grafik-ketersediaandanharga',
    component: () => import('@/views/pages/grafik/harga/hargaChartMap.vue'),
    meta: {
      pageTitle: 'Pemantauan Sembako',
    },
    
},
  {
    path: '/grafik/pemantauan/ketersediaan/:id',
    name: 'grafik-ketersediaandanharga',
    component: () => import('@/views/pages/grafik/ketersediaan/ketersediaanChartMap.vue'),
    meta: {
      pageTitle: 'Pemantauan Sembako',
    },
    
  },
  {
      path: '/pages/data-pasar',
      name: 'data-pasar',
      component: () => import('@/views/pages/data-pasar/GoodTable.vue'),
      meta: {
        pageTitle: 'Data Pasar',
      },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/pages/authentication/Register.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/pages/hak-akses',
    name: 'hak-akses',
    component: () => import('@/views/pages/hak_akses/GoodTable.vue'),
    meta: {
      pageTitle: 'Hak Akses',
    },
  },
  {
    path: '/pages/pengguna',
    name: 'pengguna',
    component: () => import('@/views/pages/pengguna/GoodTable.vue'),
    meta: {
      pageTitle: 'Pengguna',
    },
  },
  ]